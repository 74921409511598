import { useEffect } from 'react'

const useScript = (url, attrs) => {
  useEffect(() => {
    const script = document.createElement('script')

    script.src = url
    Object.keys(attrs).map((attrKey) => {
      script.setAttribute(attrKey, attrs[attrKey])
    })
    document.head.appendChild(script)

    return () => {
      document.head.removeChild(script)
    }
  }, [url, attrs])
}

export default useScript
