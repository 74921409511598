import React from 'react'
import Layout from '../components/Layout/Layout'
import Logo from '../components/misc/Logo/Logo'
import NavbarContainer from '../components/misc/NavbarContainer/NavbarContainer'
import useScript from '../hooks/useScript'

const ReservePage = () => {
  useScript('https://booking-widget.quandoo.com/index.js', {
    'data-merchant-id': '90739',
    'data-theme': 'brand',
  })

  return (
    <Layout title="Be My Guest Reservation | Vietnamilainen Ravintola | Jätkäsaari Helsinki">
      <NavbarContainer dark />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          paddingTop: '80px',
        }}
      >
        <div style={{ marginBottom: '20px' }}>
          <Logo />
        </div>
        <div id="quandoo-booking-widget" />
      </div>
    </Layout>
  )
}

export default ReservePage
